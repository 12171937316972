<template>
  <page-title-component title="Settings" />

  <div class="row">
    <div class="col-lg-6 col-md-6 layout-spacing">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-title">
              <div class="p-caption">
                <span class="caption-subject text-uppercase">Connect</span>
              </div>
            </div>
            <div class="portlet-body py-4">
              <div class="d-grid gap-2">
                <a class="social-btn amazon connect"
                   v-if="!user.amazon_associate_tag"
                   @click.prevent="$refs.amazonAssociateModal.show(user.amazon_associate_tag)"
                   href="#">
                  <div class="social-btn-icon">
                    <i class="fab fa-amazon me-2"></i>
                  </div>
                  Add Associate Tag
                </a>
                <button type="button"
                        v-else
                        @click.prevent="$refs.amazonAssociateModal.show(user.amazon_associate_tag)"
                        class="social-btn amazon logout">
                  <div class="social-btn-icon">
                    <i class="fab fa-amazon me-2"></i>
                  </div>
                  Change Associate Tag ({{user.amazon_associate_tag}})
                </button>

<!--                <a class="social-btn amazon connect"
                   v-if="user"
                   @click.prevent="$refs.amazonAssociateModal.show(user.amazon_associate_tag)"
                   href="#">
                  <div class="social-btn-icon">
                    <i class="fab fa-amazon me-2"></i>
                  </div>
                  Associate Tag
                </a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <amazon-associate-modal ref="amazonAssociateModal" />
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import AmazonAssociateModal from "@/components/AmazonAssociateModal.vue";
import {mapGetters} from "vuex";
export default {
  components: {PageTitleComponent, AmazonAssociateModal},
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  }
}
</script>


<style scoped>
.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}
.row > [class*='col-'] {
    display: flex;
    flex-direction: column;
}
.social-btn.facebook.logout,
.social-btn.amazon.logout {
    background: #9ba7ca;
}

a.social-btn:hover{
    color: #fff;
}
</style>