<template>
  <div class="login w-100">
    <div class="form-login" v-if="authPage == 'login'">
      <div class="row">

        <div class="col-md-12 text-center mb-4">
          <img :src="baseUrl + 'assets/images/logo_influencer.png'" alt="logo" class="auth-logo">
        </div>

        <div class="col-md-12">
          <form class="form-horizontal auth-form" v-if="!passwordReset">
            <div class="mb-3">
              <label for="inputEmail" class="sr-only">Email address</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('email')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputEmail"><i class="flaticon-user-7"></i> </span>
                </div>
                <input type="email"
                       id="inputEmail"
                       class="form-control"
                       v-model="loginForm.email"
                       placeholder="Email Address">
              </div>
              <v-errors :errors="errorFor('email')"></v-errors>
            </div>

            <div class="mb-3">
              <label for="inputPassword" class="sr-only">Password</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('password')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputPassword"><i class="flaticon-key-2"></i> </span>
                </div>
                <input type="password" id="inputPassword" class="form-control" v-model="loginForm.password" placeholder="Enter Password" aria-describedby="inputPassword" required >
              </div>
              <v-errors :errors="errorFor('password')"></v-errors>
            </div>

            <div class="form-group mb-0 row">
              <div class="col-12">
                <button class="btn btn-lg btn-gradient-warning btn-block btn-rounded mb-2 mt-2"
                        @click.prevent="login"
                        type="button">
                  Log In <i class="fas fa-sign-in-alt ms-1"></i>
                </button>
              </div><!--end col-->
            </div> <!--end form-group-->
          </form><!--end form-->
          <form class="form-horizontal auth-form" v-else>

            <div class="mb-3">
              <label for="email2" class="sr-only">Email</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('email')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputEmail"><i class="flaticon-user-7"></i> </span>
                </div>
                <input type="text"
                       id="email2"
                       v-model="resetForm.email"
                       :class="['form-control', {'is-invalid': errorFor('email')}]"
                       placeholder="Enter Email">
              </div>
              <v-errors :errors="errorFor('email')"></v-errors>
            </div><!--end form-group-->

            <div class="form-group mb-0 row">
              <div class="col-12">
                <button class="btn btn-lg mb-2 btn-gradient-primary w-100 waves-effect waves-light"
                        @click.prevent="resetPassword"
                        :disabled="loading"
                        type="button">
                  Send Reset Link <i class="fas fa-sign-in-alt ms-1"></i>
                </button>
              </div><!--end col-->
              <div class="col-12">
                <button class="btn btn-lg btn-gradient-warning w-100 waves-effect waves-light"
                        @click.prevent="passwordReset = false"
                        type="button">
                  Cancel
                </button>
              </div><!--end col-->
            </div> <!--end form-group-->
          </form><!--end form-->
        </div>
        <div class="col-md-12">
          <div class="login-text text-center">
            <p class="mt-3 text-white">New Here? <a href="#" @click="changePage('reg')">Register </a> a new user !</p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-login" v-else :class="[ authPage == 'reg' && showVerifiedEmailSentMsg === true ? 'verify-screen-width' : '' ]">
      <div class="row">
        <div class="col-md-12 text-center mb-4">
          <img :src="baseUrl + 'assets/images/logo_influencer.png'" alt="logo" class="auth-logo">
        </div>
        <div class="col-md-12">
          <div class="p-3 text-white rounded-3 mb-3" v-if="showVerifiedEmailSentMsg">
            <h5>Verify your email to activate your account</h5>
            <p class="mt-4">
              A verification email has been sent to <a :href="`mailto:registerForm.ema`" style="color: #af96ff;">{{ registerForm.email }}</a>.
              It will expire in 1 hour.
            </p>
          </div>
          <form class="form-horizontal auth-form" v-else>

            <div class="mb-3">
              <label for="name" class="sr-only">Name</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('name')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputName"><i class="flaticon-user-7"></i> </span>
                </div>
                <input type="text" id="name" class="form-control" v-model="registerForm.name" placeholder="Enter Name" aria-describedby="inputName" >
              </div>
              <v-errors :errors="errorFor('name')"></v-errors>
            </div>

            <div class="mb-3">
              <label for="email" class="sr-only">Email</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('email')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputEmail"><i class="flaticon-email-fill-2"></i> </span>
                </div>
                <input type="email" id="email" class="form-control" v-model="registerForm.email" placeholder="Enter Email" aria-describedby="inputEmail" >
              </div>
              <v-errors :errors="errorFor('email')"></v-errors>
            </div>

            <div class="mb-3">
              <label for="password" class="sr-only">Password</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('password')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputEmail"><i class="flaticon-key-2"></i> </span>
                </div>
                <input type="password"
                       :class="['form-control', {'is-invalid': errorFor('password')}]"
                       v-model="registerForm.password"
                       placeholder="Enter Password">
              </div>
              <v-errors :errors="errorFor('password')"></v-errors>
            </div><!--end form-group-->

            <div class="mb-3">
              <label for="password" class="sr-only">Confirm Password</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('password')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputEmail"><i class="flaticon-key-2"></i> </span>
                </div>
                <input type="password"
                       class="form-control"
                       v-model="registerForm.password_confirmation"
                       placeholder="Enter Confirm Password">
              </div>
            </div><!--end form-group-->

            <div class="form-group mb-0 row">
              <div class="col-12">
                <button class="btn btn-lg btn-gradient-warning btn-block btn-rounded mb-2 mt-2"
                        @click.prevent="register"
                        :disabled="loading"
                        type="button">
                  Register <i class="fas fa-sign-in-alt ms-1"></i>
                </button>
              </div><!--end col-->
            </div> <!--end form-group-->

          </form><!--end form-->
        </div>
        <div class="col-md-12">
          <div class="login-text text-center">
            <p class="mt-3 text-white">Already Have an Account? <a href="#" @click="changePage('login')">Login </a> here!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authPage: 'login',
      passwordReset: false,
      loginForm: {
        email: '',
        password: '',
        device: 'web'
      },
      resetForm: {
        email: ''
      },
      registerForm: {
        invitation: this.$route.query.invitation,
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      }
    }
  },
  mounted () {
    document.body.classList.add('logo')
  },
  unmounted () {
    document.body.classList.remove('logo')
  },
  methods: {
    changePage(page) {
      this.allErrors = null;
      this.authPage = page;
    },
    login() {
      this.allErrors = null;

      this.$store.dispatch('auth/login', this.loginForm)
        .then(() => {
          window.location.href = process.env.VUE_APP_BASE
        })
        .catch((err) => {
          this.allErrors = err.data.errors
        })
        .finally(() => this.loading = false);
    },
    register() {
      this.allErrors = null;
      this.loading = true;

      this.$store.dispatch('auth/register', this.registerForm)
        .then(() => {
          window.location.href = process.env.VUE_APP_BASE
        })
        .catch((err) => {
          this.allErrors = err.errors
        })
        .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

.auth-logo{
  max-width: 225px;
  margin-bottom: 58px;
}

.form-login .input-group.mb-1.is-invalid {
  border: 1px solid #fd7167;
  border-radius: 30px;
}

.form-login .form-control{
  caret-color: #fff;
}

::-webkit-input-placeholder,
::-ms-input-placeholder,
::placeholder{
  color: red !important;
  font-size: 16px;
  position: relative;
  box-sizing: border-box;
  height: auto;
  font-size: 16px;
  color: #fff;
}

input::placeholder {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: #fff !important;
}

.verify-screen-width {
  max-width: 530px;
}

</style>