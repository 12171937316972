<template>
  <div class="modal fade" id="invite-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Invite</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <select class="select2 select-emails"
                      style="width: 100%"
                      multiple="multiple"
                      data-placeholder="Type Emails">
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="loading"
                  @click="submit">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      loading: false,
      form: {
        emails: []
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('invite-modal'))
    this.initListsSelect2();
  },
  methods: {
    show() {
      this.form.emails = [];
      this.modal.show();

      if (window.$('.select-emails').data('select2')) {
        window.$('.select-emails').val(['1', '2']).trigger('change');
      }
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.loading = true;

      this.axios.post('/invite', this.form)
        .then(() => {
          this.$emit('invited')
          this.hide();
        })
        .finally(() => {
          this.loading = false;
        })
    },
    initListsSelect2() {
      if (window.$('.select-emails').data('select2')) {
        window.$('.select-emails').select2('destroy');
      }

      let self = this;

      window.$('.select-emails').select2({
        tags: true,
        tokenSeparators: [',', ' '],
        createTag: function (params) {
          if (params.term.indexOf('@') === -1) {
            return null;
          }

          return {
            id: params.term,
            text: params.term
          }
        }
      });

      window.$('.select-emails').on('change', function () {
        self.form.emails = window.$(".select-emails").select2("val");
      });


    },
  }
}
</script>