<template>
  <div class="modal fade"
       id="modal-influencer-campaign-details"
       role="dialog"
       data-bs-focus="false"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-facebook-campaign-detailsLabel">Campaign Info</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="statbox widget box p-0" v-if="campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0" >
                  <tbody>
                  <tr>
                    <th>Campaign Name</th>
                    <td>{{ removeUnderscore(campaign.amazon_campaign) }}</td>
                  </tr>
                  <tr>
                    <th>Link</th>
                    <td v-if="campaign.influencer_short_link">
                      <div class="row">
                        <div class="col-6">{{ campaign.influencer_short_link }}</div>
                        <div class="col-6 text-right">
                          <a href=""
                             @click.prevent="copyLink(campaign.influencer_short_link)">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>
                    </td>

                    <td v-else>
                      {{ getFullLink }}
                      <div class="row">
                        <div class="col-12 text-right">
                          <a href=""
                             @click.prevent="copyLink">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="user.amazon_associate_tag">
                    <th>Link With Amazon Tag</th>
                    <td>
                      <div class="row" v-if="campaign.influencer_short_link_with_amazon_tag">
                        <div class="col-6">{{ campaign.influencer_short_link_with_amazon_tag }}</div>
                        <div class="col-6 text-right">
                          <a href=""
                             @click.prevent="copyLink(campaign.influencer_short_link_with_amazon_tag)">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>

                      <div class="row" v-else>
                        <div class="col-12 text-right">
                          <a v-if="!campaign.influencer_short_link_with_amazon_tag && !generatingShortUrl"
                             href=""
                             @click.prevent="generateShortLink('influencer_short_link_with_amazon_tag')">
                            <i class="fas fa-link"></i> Generate link with Associate Tag
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      generatingShortUrl: false,
      modal: null,
      campaign: null,
    }
  },
  computed: {
    getFullLink() {
      if (this.campaign) {
        if (this.campaign.influencer_full_link) {
          return this.campaign.influencer_full_link;
        } else {
          return "https://www.amazon.com/dp/" + this.campaign.influencer_asin + this.campaign.influencer_campaign_url;
        }
      }

      return "";
    },
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-influencer-campaign-details'));
  },
  methods: {
    generateShortLink(column) {
      this.generatingShortUrl = true;

      let url = this.campaign.influencer_full_link;

      if (column === 'influencer_short_link_with_amazon_tag') {
        if (this.campaign.product_id) {
          url += '?tag=';
        } else {
          url += '&tag=';
        }

        url += this.user.amazon_associate_tag;
      }
      this.axios.post('/short-link/generate', {
        amazon_ad: this.campaign.id,
        column: column,
        url: url
      }).then(res => {
        this.campaign[column] = res.data.data[column];
      }).finally(() => {
        this.generatingShortUrl = false;
      })
    },
    copyLink(link = null) {
      let url = '';

      if (link)
        url = link;
      else
        url = "https://www.amazon.com/dp/" + this.campaign.influencer_asin + this.campaign.influencer_campaign_url;

      navigator.clipboard.writeText(url);
      this.showSuccessMsg("Link Copied!")
    },
    show(campaign) {
      this.campaign = campaign;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>