<template>
  <div class="box box-shadow bg-white mt-4 p-3 pb-4 rounded">
    <div class="row">
      <div class="col-md-3">
        <div class="input-group mb-md-0 mb-1">
          <input type="text" id="reportrange" class="form-control" value="10/24/1984" />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"><i class="flaticon-calendar"></i></span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <input type="text"
               v-model="search"
               class="form-control"
               placeholder="Search">
      </div>
      <div class="col-md-6 text-right">
        <a href="#" class="mr-3" @click.prevent="collapseAll(true)">- Collapse All</a>
        <a href="#" @click.prevent="collapseAll(false)">+ Expand All</a>
      </div>
    </div>
  </div>

  <div class="row layout-spacing mt-3" v-if="loading">
    <div class="col-lg-12">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area rounded" >
          <div class="text-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-else>
    <div class="row mt-3"
         v-for="(camps, i) in groupedCampaign"
         :key="'account_'+i">
      <div class="col-lg-12 layout-spacing">
        <div class="widget portlet-widget">
          <div class="widget-content widget-content-area">
            <div class="portlet portlet-basic">
              <div class="portlet-title">
                <div class="p-caption">
                  <div class="row">
                    <div class="col-6">
                      <span class="caption-subject">
                        <span v-if="aliases.find(a => a.account_id == camps[0].account.id)">
                          {{ aliases.find(a => a.account_id == camps[0].account.id).name }}

                          ({{ camps[0].account.name }}) - {{ currentCurrency + camps.reduce((a,b) => a + b.total_sale, 0) }}
                        </span>
                        <span v-else>
                          {{ camps[0].account.name }} - {{ currentCurrency + camps.reduce((a,b) => a + b.total_sale, 0) }}
                        </span>


                        <a href="#"
                           @click.prevent="editAlias(camps[0].account)"
                           class="ml-2 fs-10 text-info">Edit</a>
                      </span>
                    </div>
                    <div class="col-6 text-right">
                      <a href="#"
                         @click="collapse.find(c => c.account_id === camps[0].account.id).collapse = !collapse.find(c => c.account_id === camps[0].account.id).collapse">
                        <span v-if="!collapse.find(c => c.account_id === camps[0].account.id).collapse">- Collapse</span>
                        <span v-else>+ Expand</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="portlet-body py-4" v-if="!collapse.find(c => c.account_id === camps[0].account.id).collapse">
                <div class="table-responsive">
                  <table id="table2" class="tablesaw table-striped" data-tablesaw-mode="swipe" data-tablesaw-sortable data-tablesaw-sortable-switch data-tablesaw-minimap data-tablesaw-mode-switch>
                    <thead>
                    <tr>
                      <th scope="col">Amazon Campaign</th>
                      <th scope="col">
                        Spend
                      </th>
                      <th scope="col">
                        Impressions
                      </th>
                      <th scope="col">
                        Clicks
                      </th>
                      <th scope="col">CTR</th>
                      <th scope="col">
                        Add To Cart
                      </th>
                      <th scope="col">
                        Conversions
                      </th>
                      <th scope="col">
                        Revenue
                      </th>
                      <th class="text-right" scope="col">
                        Action
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(campaign, i) in camps" :key="'campaign_'+i">
                      <tr>
                        <td>{{ removeUnderscore(campaign.name) }}</td>
                        <td class="no-wrap">
                          <span>
                            <span v-if="campaign.influencer_amount">{{ this.currentCurrency + campaign.influencer_amount.toFixed(2) }}</span>
                            <span v-else-if="campaign.influencer_percentage">{{ campaign.influencer_percentage.toFixed(2) }}%</span>
                          </span>
                        </td>
                        <td>
                          <div class="spinner-border spinner-border-sm"
                               v-if="loadingMetrics"
                               role="status"></div>
                          <span v-else>{{ campaign.impressions }}</span>
                        </td>
                        <td>
                          <div class="spinner-border spinner-border-sm"
                               v-if="loadingMetrics"
                               role="status"></div>
                          <span v-else>{{ campaign.clicks }}</span>
                        </td>
                        <td>
                          <div class="spinner-border spinner-border-sm"
                               v-if="loadingMetrics"
                               role="status"></div>
                          <span v-else>{{ campaign.impressions ? ((campaign.clicks * 100 ) / campaign.impressions).toFixed(2) : 0 }}%</span>
                        </td>
                        <td>
                          <div class="spinner-border spinner-border-sm"
                               v-if="loadingMetrics"
                               role="status"></div>
                          <span v-else>{{ campaign.atc }}</span>
                        </td>
                        <td>
                          <div class="spinner-border spinner-border-sm"
                               v-if="loadingMetrics"
                               role="status"></div>
                          <span v-else>{{ campaign.total_purchase }}</span>
                        </td>
                        <td class="no-wrap">
                          <div class="spinner-border spinner-border-sm"
                               v-if="loadingMetrics"
                               role="status"></div>
                          <span v-else>{{ this.currentCurrency }} {{ campaign.total_sale.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</span>
                        </td>
                        <td class="text-right">
                          <button class="btn btn-sm btn-info mr-2"
                                  @click="$refs.campaignInfoModal.show(campaign)">Campaign Info</button>

                          <button class="btn btn-sm btn-danger"
                                  @click="unlink(campaign)">Unlink</button>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                    <tfoot>
                    <tr>
                      <th>Total</th>
                      <th>
                        {{ currentCurrency + camps.reduce((a,b) => a + b.influencer_amount, 0).toFixed(2) }}
                      </th>
                      <th>
                        {{ camps.reduce((a,b) => a + b.impressions, 0) }}
                      </th>
                      <th>
                        {{ camps.reduce((a,b) => a + b.clicks, 0) }}
                      </th>
                      <th></th>
                      <th>
                        {{ camps.reduce((a,b) => a + b.atc, 0) }}
                      </th>
                      <th>
                        {{ camps.reduce((a,b) => a + b.total_purchase, 0) }}
                      </th>
                      <th>
                        {{ currentCurrency + camps.reduce((a,b) => a + b.total_sale, 0).toFixed(2) }}
                      </th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <campaign-info-modal ref="campaignInfoModal" />
</template>

<script>
import CampaignInfoModal from "@/views/CampaignInfoModal.vue";
export default {
  components: {CampaignInfoModal},
  data() {
    return {
      collapse: [],
      aliases: [],
      search: '',
      table: {
        sortColumn: 'impressions',
        sortOrder: 'desc',
      },
      currentCurrency: '$',
      loadingMetrics: false,
      campaigns: [],
      queryParams: {
        startDate: window.moment().subtract(30, "days").startOf("month").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  computed: {
    groupedCampaign() {
      if (this.search) {
        this.campaigns.forEach(cam => {
          let alias = this.aliases.find(a => a.account_id == cam.account.id)

          if (alias) {
            cam.alias = alias;
          }
        })

        return window._.chain(
            this.campaigns.filter(
              campaign => (this.removeUnderscore(campaign.name).toLowerCase().includes(this.search.toLowerCase())) ||
                (campaign.account.name.toLowerCase().includes(this.search.toLowerCase()) ||
                  (campaign.alias && campaign.alias.name.toLowerCase().includes(this.search.toLowerCase())))
            )
          )
          .groupBy("account_id")
          .value()
      } else {
        return window._.chain(this.campaigns)
          .groupBy("account_id")
          .value()
      }
    },
    sortedCampaigns() {
      let c = [...this.campaigns];
      if (this.table.sortOrder === 'asc')
        return c.sort((a,b) => a[this.table.sortColumn] - b[this.table.sortColumn]);
      else
        return c.sort((a,b) => b[this.table.sortColumn] - a[this.table.sortColumn]);
    },
  },
  created() {
    this.loadAliases();
    this.loadData();
  },
  mounted() {
    let t = window.moment().subtract(30, "days").startOf("month");
    let a = window.moment();
    let self = this;

    window.$("#reportrange").daterangepicker({
        startDate: t,
        endDate: a,
        locale: {
          format: 'MMM D, YYYY'
        },
        ranges: {
          Today: [window.moment(), window.moment()],
          Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
          "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
          "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
          "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
          "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
          "All Time": [window.moment().subtract(3, "years").startOf("month"), window.moment()],
        },
      },
      function (t, a) {
        self.queryParams.startDate = t.format('YYYYMMDD');
        self.queryParams.endDate = a.format('YYYYMMDD');
        self.loadData();
      }
    )
  },
  methods: {
    collapseAll(collapse) {
      this.collapse.forEach(c => {
        c.collapse = collapse;
      })
    },
    sortTable(col) {
      let order = 'asc';

      if (col === this.table.sortColumn) {
        if (this.table.sortOrder === 'asc')
          order = 'desc';
      }

      this.table.sortColumn = col;
      this.table.sortOrder = order;
    },
    loadAliases() {
      this.axios.get('/aliases')
        .then(res => {
          this.aliases = res.data.data;
        })
    },
    checkMetrics() {
      this.axios.get('/metrics', {
          params: this.queryParams
        })
        .then(res => {
          const results = res.data;

          results.forEach(item => {
            let campaign = this.campaigns.find(c => c.id === item.id);

            if (campaign) {
              campaign.clicks = item.clicks;
              campaign.impressions = item.impressions;
              campaign.atc = item.atc;
              campaign.total_purchase = item.total_purchase;
              campaign.total_sale = item.total_sale;
            }
          });

          this.loadingMetrics = false;
        });
    },
    loadData() {
      this.loading = true;
      this.campaigns = [];
      this.loadingMetrics = true;

      this.axios.get('/campaigns', {
          params: this.queryParams
        })
        .then(response => {
          this.campaigns = response.data.data;
          window._.uniq(this.campaigns.map(c => c.account_id)).forEach(id => {
            this.collapse.push({
              account_id: id,
              collapse: false,
            })
          });

          this.campaigns.forEach(campaign => {
            campaign.clicks = 0;
            campaign.impressions = 0;
            campaign.atc = 0;
            campaign.total_purchase = 0;
            campaign.total_sale = 0;
          })

          this.checkMetrics();
        })
        .catch(err => {
          this.errorMsg = err.response.data.message
        })
        .finally(() => this.loading = false)
    },
    unlink(ad) {
      this.$swal({
        title: 'Are you sure want to unlink?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, unlink it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/campaigns/unlink', {
            amazon_ad_id: ad.id
          }).then(() => {
            let index = this.campaigns.find(c => c.id === ad.id);
            this.campaigns.splice(index, 1);

            this.$swal.fire(
              'Unlinked',
              'Campaign has been unlinked!',
              'success'
            );
          });
        }
      });
    },
    async editAlias(account) {
      const {value: name} = await this.$swal.fire({
        title: 'Alias',
        inputLabel: 'Alias for ' + account.name,
        input: 'text',
        showCancelButton: true,
      })

      this.axios.post('/alias/change', {
        name: name,
        account_id: account.id
      }).then(() => {
        this.loadAliases()
      });
    }
  }
}
</script>